<template>
  <BaseSection no-gutters class="container px-4 sm:px-6 lg:px-8 my-14 block-icon-cards">
    <BaseTitle level="h2">
      {{ data.title }}
    </BaseTitle>
    <div class="block-icon-cards__grid" :class="computedCols">
      <BenefitsCard
        v-for="(item, idx) in computedItems"
        :key="idx"
        :image-src="item.imageSrc"
        :title="item.title"
        :body="item.body"
        :type="item.type"
        :center-icon="item.centerIcon"
      />
    </div>
  </BaseSection>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BaseSection from '../BaseSection.vue';
import BaseTitle from '../BaseTitle.vue';
import BenefitsCard, { type BenefitsCardProps } from '../cards/BenefitsCard.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const computedItems = computed(() => {
  const cardItems: BenefitsCardProps[] = props.data.cards.map((item: Record<string, any>) => {
    const imageSrc = item?.icon?.original?.src || {};
    const card: BenefitsCardProps = {
      body: item.content,
      title: item.title,
      imageAlt: item?.icon?.title || '',
      type: 'icon',
      centerIcon: item?.center_aligned,
      imageSrc,
    };

    return card;
  });

  return cardItems;
});

const computedCols = computed(() => {
  return `cols${props.data.columns || 4}`;
});
</script>

<style scoped>
.block-icon-cards__grid {
  @apply grid gap-2 grid-cols-1 md:grid-cols-2;
  &.cols3 {
    @apply lg:grid-cols-3;
  }
  &.cols4 {
    @apply lg:grid-cols-4
  }
}
</style>
